export default {
  namespaced: true,
  state: {
    freshdeskWidgetEnabled: false,
    customer: undefined,
    lastUpdated: undefined,
  },
  mutations: {
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setLastUpdate(state, lastUpdated) {
      state.lastUpdated = lastUpdated;
    },
  },
  getters: {
    getFreshdeskWidgetEnabled(state) {
      return state.freshdeskWidgetEnabled;
    },
    getCustomer(state) {
      return state.customer;
    },
    requoteMarkup(state) {
      try {
        return state.customer.financial.requoteMarkup;
      } catch (error) {
        return 0;
      }
    },
    hasBrandedPricingOn(state) {
      try {
        return state.customer.eCommerceIntegration.priceDisplayMode === 1;
      } catch (error) {
        return false;
      }
    },
    customer_has_branded_mode_enabled(state) {
      try {
        return state.customer.eCommerceIntegration.enabled;
      } catch (error) {
        return false;
      }
    },
    has_price_breakdown_enabled(state) {
      try {
        return !state.customer.financial.omitPriceBreakdown;
      } catch (error) {
        return false;
      }
    },
    customer_logo(state) {
      try {
        return `${window.touch.imageBaseURL}${state.customer.styling.logo}`;
      } catch (error) {
        // @todo log the error here
        return '';
      }
    },
    customer_name(state) {
      try {
        return `${state.customer.company.name}`;
      } catch (error) {
        // @todo log the error here
        return '';
      }
    },
    branded_mode_settings(state) {
      try {
        let { webAddress } = state.customer.eCommerceIntegration;

        if (webAddress && !webAddress.includes('//')) {
          webAddress = `http://${webAddress}`;
        }

        return {
          company_name: state.customer.company.name,
          phone: state.customer.eCommerceIntegration.mobilePhone,
          email: state.customer.eCommerceIntegration.email,
          webAddress,
          pricingDisplayMode: state.customer.eCommerceIntegration.priceDisplayMode,
          privacyPolicyDocumentId: state.customer.eCommerceIntegration.privacyPolicyDocumentId,
          restart_url: `/launch/${state.customer.eCommerceIntegration.alias}`,
        };
      } catch (error) {
        return false;
      }
    },
  },
  actions: {
    async setLastUpdated({ commit }, lastUpdated) {
      commit('setLastUpdate', lastUpdated);
    },
    async setCustomer({ rootState, commit }, customerId) {
      const customer = await window.touch.customerGet(customerId, rootState.auth.staffLogin);
      commit('setCustomer', customer);
    },
    async customerGet({ rootState }, customerId) {
      return window.touch.customerGet(customerId, rootState.auth.staffLogin);
    },
    async customerUpdate({ rootState }, customer) {
      return window.touch.customerUpdate(customer, rootState.auth.staffLogin);
    },
    resetCustomer({ commit }) {
      commit('setCustomer', undefined);
    },
    async customerUpdateRaw(context, customer) {
      return window.touch.customerUpdateRaw(customer);
    },
    async customerBranchUpdate(context, branch) {
      return window.touch.customerBranchUpdate(branch);
    },
    async branchUpdateAddress(context, address) {
      return window.touch.branchUpdateAddress(address);
    },
    async updateCustomerRequoteDetails(context, customer) {
      return window.touch.updateCustomerRequoteDetails(customer);
    },
    async customerUpdateDeliveryDays({ rootState }, customer) {
      return window.touch.customerUpdateDeliveryDays(customer, rootState.auth.staffLogin);
    },
    async customerUpdateUsers({ rootState }, { customer, usersIds }) {
      return window.touch.customerUpdateUsers(customer, rootState.auth.staffLogin, usersIds);
    },
    async customerCreateUsers({ rootState }, { customer }) {
      return window.touch.customerUpdateUsers(customer, rootState.auth.staffLogin);
    },
    async customerUpdateAddresses({ rootState }, { customer }) {
      return window.touch.customerUpdateAddresses(customer, rootState.auth.staffLogin);
    },
    async customerUpdateVendorAddress({ rootState }, customer) {
      return window.touch.customerUpdateVendorAddress(customer, rootState.auth.staffLogin);
    },
    async customerCreate(context, customer) {
      return window.touch.customerCreate(customer);
    },
    async customerCreateBranch(context, branch) {
      return window.touch.customerCreateBranch(branch);
    },
    async customerListContacts() {
      return window.touch.customerListContacts();
    },
    async customerGetContact(context, ContactId) {
      return window.touch.customerGetContact(ContactId);
    },
    async customerEditContact(context, Contact) {
      return window.touch.customerEditContact(Contact);
    },
    async customerCreateContact(context, Contact) {
      return window.touch.customerCreateContact(Contact);
    },
    async staffListCustomers(context, options) {
      return window.touch.staffListCustomers(
        options.limit, options.offset, options.search, undefined, undefined,
        options.customerStatus, options.generalLicenseStatus, options.branchCompanyId
      );
    },
    async staffListStaff(context, { options, branchCompanyId }) {
      return window.touch.staffListStaff(options.limit, options.offset, branchCompanyId);
    },
    async customerListBranches(context, options) {
      return window.touch.customerListBranches(options.limit, options.offset, options.search);
    },
    async staffGetTaxRates() {
      return window.touch.staffGetTaxRates();
    },
    async staffGetStaffContact(context, ContactId) {
      return window.touch.staffGetStaffContact(ContactId);
    },
    async staffUpdateTaxRate(context, TaxRate) {
      return window.touch.staffUpdateTaxRate(TaxRate);
    },
    async staffAddTaxRate(context, TaxRate) {
      return window.touch.staffAddTaxRate(TaxRate);
    },
    async staffDeleteTaxRate(context, id) {
      return window.touch.staffDeleteTaxRate(id);
    },
    async formatCustomerUpdateFinancialSettings(
      context,
      { vatEnabled, taxValue, fittingRate, currencyId },
    ) {
      return {
        Financial: {
          vatEnabled: Boolean(vatEnabled),
          taxValue: Number(taxValue),
          fittingRate: Number(fittingRate),
          currencyId: currencyId ? Number(currencyId) : undefined,
        },
      };
    },
    async formatCustomerUpdateCommerceIntegrationSettings(
      context,
      { eCommerceIntegrationSettings },
    ) {
      return {
        ECommerceIntegration: {
          Alias: eCommerceIntegrationSettings.alias,
          WebAddress: eCommerceIntegrationSettings.webAddress,
          Email: eCommerceIntegrationSettings.email,
          mobilePhone: eCommerceIntegrationSettings.mobilePhone,
          privacyPolicyDocumentId: eCommerceIntegrationSettings.privacyPolicyDocumentId,
          addressId: eCommerceIntegrationSettings.addressId,
          companyRegNumber: eCommerceIntegrationSettings.companyRegNumber,
          fensaRegistrationNumber: eCommerceIntegrationSettings.fensaRegistrationNumber,
          vatRegistrationNumber: eCommerceIntegrationSettings.vatRegistrationNumber,
          priceDisplayMode: Number(eCommerceIntegrationSettings.priceDisplayMode),
        },
      };
    },
    async formatCustomerUpdateLogo(context, { logo }) {
      return {
        Styling: {
          logo,
        },
      };
    },
    async customerUpdateFinancialSettings(context, { vatEnabled, taxValue, fittingRate }) {
      return window.touch.customerUpdateFinancialSettings({ vatEnabled, taxValue, fittingRate });
    },
    async customerUpdateCommerceIntegrationSettings(
      context,
      { eCommerceIntegrationSettings, customerId },
    ) {
      return window.touch.customerUpdateCommerceIntegrationSettings(
        eCommerceIntegrationSettings,
        customerId,
      );
    },
    async customerUpdateLogo(context, { logo, customerId }) {
      return window.touch.customerUpdateLogo(logo, customerId);
    },
    async customerGetProductOverrides() {
      return window.touch.customerGetProductOverrides();
    },
    async customerGetAllProductsWithOverrides(customerId) {
      return window.touch.customerGetAllProductsWithOverrides(customerId);
    },
    async customerUpdateProductOverrides(context, overrides) {
      return window.touch.customerUpdateProductOverrides(overrides);
    },
    async getPrivacyPolicyDocument() {
      return window.touch.getPrivacyPolicyDocument();
    },
    async getTermsAndConditionsDocument() {
      return window.touch.getTermsAndConditionsDocument();
    },
    async customerUploadDocumentWithUrl(context, document) {
      return window.touch.customerUploadDocumentWithUrl(document);
    },
    async staffGetDiscountList(context, datasetId) {
      return window.touch.staffGetDiscountList(datasetId);
    },
    async staffGetCustomerDiscounts(context, { customerId, datasetId }) {
      return window.touch.staffGetCustomerDiscounts(customerId, datasetId);
    },
    async staffUpdateDiscounts(context, { customerId, datasetId, discounts }) {
      return window.touch.staffUpdateDiscounts(customerId, datasetId, discounts);
    },
    async customerGetProcessingLevels() {
      return window.touch.customerGetProcessingLevels();
    },
    async customerGetCompany(context, companyId) {
      return window.touch.customerGetCompany(companyId);
    },
  },
};
