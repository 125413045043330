<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Portal Customers'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Portal Customers
        <HelpPoint class="ml-2" freshdesk-article-id="101000455581" />
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for customer..." />
    </portal>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center gap-3">
        <a v-if="is_admin_user" class="btn-action" @click.stop.prevent="create_user_popup_open = true"> Create New </a>
        <div v-if="branches.length > 0">
          <FilterDropdown
            label="Branch"
            query-label="branchFilter"
            :filters="branches"
            :default-required="true"
          />
        </div>
      </div>
    </template>
    <portal v-if="create_user_popup_open" to="portal_popup">
      <modal-window
        :modal_open="create_user_popup_open"
        title="Add Customer"
        @close="create_user_popup_open = false"
      >
        <customer-create @close="create_user_popup_open = false"></customer-create>
      </modal-window>
    </portal>
    <slot></slot>
    <loading :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-5">
      <div class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th> Account Code </th>
              <th> Company </th>
              <th class="xs:hidden md:table-cell"> Tel </th>
              <th> Email </th>
              <th class="xs:hidden md:table-cell"> Town </th>
              <th class="xs:hidden md:table-cell"> County </th>
              <th>
                <FilterDropdown
                  label="License"
                  query-label="licenseFilter"
                  :filters="[
                    {id: enums.licenseStatusType.ENABLED, name: 'Enabled', dot: 'bg-green-500'},
                    {id: enums.licenseStatusType.DISABLED, name: 'Disabled', dot: 'bg-red-500'}
                  ]"
                />
              </th>
              <th>
                <FilterDropdown
                  label="Status"
                  query-label="statusFilter"
                  :filters="[
                    {id: enums.customerStatus.LIVE, name: 'Live', dot: 'bg-green-500'},
                    {id: enums.customerStatus.ON_STOP, name: 'On Stop', dot: 'bg-blue-500'},
                    {id: enums.customerStatus.ARCHIVED, name: 'Archived', dot: 'bg-red-500'}
                  ]"
                />
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(customer, index) in customers"
              :key="index"
              tag="tr"
              :to="customer.url"
              :class="{ '_hovered' : hovered === index }"
              @mouseover.native="hovered = index"
              @mouseleave.native="hovered = null"
            >
              <td>
                <div v-if="customer.accountCode " class="td-title w-24"> Account <br class="sm:hidden"> Code: </div>
                <div class="td-content">{{ customer.accountCode }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Company: </div>
                <div class="td-content">{{ customer.name }}</div>
              </td>
              <td class="xs:hidden md:table-cell">
                <div v-if="customer.phone" class="td-title w-24"> Tel: </div>
                <div class="td-content">{{ customer.phone }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Email: </div>
                <div class="td-content break-all">{{ customer.email }}</div>
              </td>
              <td class="xs:hidden md:table-cell">
                <div v-if="customer.address.town" class="td-title w-24"> Town: </div>
                <div class="td-content">{{ customer.address.town }}</div>
              </td>
              <td class="xs:hidden md:table-cell">
                <div v-if="customer.address.county" class="td-title w-24"> County: </div>
                <div class="td-content">{{ customer.address.county }}</div>
              </td>
              <td>
                <div class="td-title w-24"> License: </div>
                <div class="td-content text-center">
                  <div v-if="customer.generalLicenseStatus === enums.licenseStatusType.ENABLED" class="bg-green-500 rounded text-white px-10 py-1">
                    Enabled
                  </div>
                  <div v-else class="bg-red-500 rounded text-white px-10 py-1">
                    Disabled
                  </div>
                </div>
              </td>
              <td>
                <div class="td-title w-24"> Status: </div>
                <div class="td-content text-center">
                  <div v-if="customer.customerStatus === enums.customerStatus.ARCHIVED" class="bg-red-500 rounded text-white px-10 py-1">
                    Archived
                  </div>
                  <div v-else-if="customer.customerStatus === enums.customerStatus.ON_STOP" class="bg-blue-500 rounded text-white px-10 py-1">
                    On Stop
                  </div>
                  <div v-else class="bg-green-500 rounded text-white px-10 py-1">
                    Live
                  </div>
                </div>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil"></i>
                </div>
              </td>
            </router-link>
          </tbody>
        </table>
        <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
          <div class="flex justify-between">
            <div>
              No Customers to show
            </div>
            <div v-if="$route.query.search || $route.query.statusFilter || $route.query.licenseFilter">
              <a class="btn-action" @click.stop="$router.replace({ query: undefined }).catch(() => {});">
                Clear Filters
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customers.length > 0" class="mt-3">
        <pagination
          :key="JSON.stringify($route.query)"
          :total_items="total_items"
          :limit="limit"
          @load-feed="loadFeed"
        ></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import Pagination from '@/components/shared/Pagination.vue';
import CustomerCreate from '@/components/shared/CustomerCreate.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    pagination: Pagination,
    'customer-create': CustomerCreate,
    HelpPoint,
    Breadcrumbs,
    SearchBox,
    FilterDropdown
  },
  data() {
    return {
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      create_user_popup_open: false,
      hovered: null,
    };
  },
  computed: {
    branches () {
      return this.$store.state.auth.branches.map(x => ({
        id: x.branchCompanyId,
        name: x.name,
        isPrimary: x.isPrimary
      }))
    }
  },
  watch: {
    '$route.query': {
      handler(queryNew, queryOld) {
        if (queryNew?.search !== queryOld?.search) {
          this.$router.push({ query: { ...this.$route.query, statusFilter: undefined, licenseFilter: undefined } }).catch(() => {});
        }
        this.loadFeed();
      },
      immediate: true
    },
  },
  async created () {
    if (this.branches.length > 0) {
      this.$router.push({ query: { ...this.$route.query, branchFilter: this.branches.find(x => x.isPrimary).id } }).catch(() => {});
    }
  },
  methods: {
    ...mapActions({
      staffListCustomers: 'user/staffListCustomers',
    }),
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.staffListCustomers({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
        customerStatus: this.$route.query.statusFilter ? parseInt(this.$route.query.statusFilter) : undefined,
        generalLicenseStatus: this.$route.query.licenseFilter ? parseInt(this.$route.query.licenseFilter) : undefined,
        branchCompanyId: this.$route.query.branchFilter ? parseInt(this.$route.query.branchFilter) : undefined
      });
      this.customers = response.customers;
      this.total_items = response.total_items;
      this.loading = false;
    },
  }
};
</script>