<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Details'],
        ]"
      />
    </template>
    <template #header_page_title> Customer Details </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <span class="font-medium hidden xs:inline-flex flex-col justify-around pr-3">Account Code</span>
        <div class="text-brand-primary inline-flex flex-col justify-around">
          {{ customer.financial.accountCode }}
        </div>
        <div class="inline-flex flex-col justify-around ml-3">
          <div
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'account_code'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>
    </template>
    <customer-navigation-menu></customer-navigation-menu>
    <div
      id="customer_index_scrolling_outer_wrapper"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
    >
      <account-code
        v-if="edit_mode_section === 'account_code'"
        :customer="customer"
        @close="(edit_mode_section = 'none'), loadCustomer()"
        @loadCustomer="loadCustomer"
        @isLoading="loading = $event"
      />
      <company-details
        v-else-if="edit_mode_section === 'company_details'"
        :prop_customer="customer"
        :staff-contacts="staffContacts"
        :logged-in-user="loggedInUser"
        :logo_src="logo_src"
        @close="(edit_mode_section = 'none'), loadCustomer()"
        @isLoading="loading = $event"
        @updateCustomer="$emit('updateCustomer')"
      />
      <financial-details
        v-else-if="edit_mode_section === 'financial_details'"
        :customer="customer"
        :logged-in-user="loggedInUser"
        :tax_rates="tax_rates"
        @close="(edit_mode_section = 'none'), loadCustomer()"
        @isLoading="loading = $event"
      />
      <div class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16">
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Company</div>
          <div class="flex w-full">
            <div v-if="!company_logo_missing">
              <div
                class="rounded-full border flex flex-col justify-around overflow-hidden relative mr-4"
                style="width: 50px; height: 50px"
              >
                <img
                  :id="'userImg_' + customer.id"
                  :src="logo_src"
                  class="m-auto"
                  @error="company_logo_missing = true"
                  @load="positionAvatar"
                />
              </div>
            </div>
            <div class="flex-grow">
              <div class="flex flex-col justify-around">{{ customer.company.name }}</div>
            </div>
          </div>
        </div>
        <div v-if="customer.customer.salesSectorType" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Customer Type</div>
          <div class="flex flex-col justify-around capitalize">
            {{ deliverySalesSectorType[customer.customer.salesSectorType][0].toLowerCase() }}
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Contact Number</div>
          <div class="flex flex-col justify-around">{{ customer.company.phone }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Sales Contact</div>
          <div
            class="flex flex-col justify-around"
            v-text="
              customer.customer.salesContact
                ? customer.customer.salesContact.firstName +
                  ' ' +
                  customer.customer.salesContact.lastName +
                  ' - ' +
                  customer.customer.salesContact.email
                : 'None'
            "
          ></div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Email</div>
          <div class="flex flex-col justify-around">{{ customer.company.email }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Website</div>
          <div class="flex flex-col justify-around">{{ customer.company.web }}</div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Dealer Network Partner</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.customer.dealerNetworkPartner ? 'Yes' : 'No'"
          ></div>
        </div>
        <div v-if="hasDebugToggle" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Debug Customer</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.customer.debug ? 'Yes' : 'No'"
          ></div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">License Status</div>
          <div
            class="flex flex-col justify-around"
            v-text="
              customer.customer.generalLicenseStatus === enums.generalLicenseStatus.ENABLED
                ? 'Customer Enabled'
                : 'Customer Disabled'
            "
          ></div>
        </div>
        <div v-if="isBranchManagerAvailable" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Linked Branches</div>
          <div v-if="customer.branches.length > 0" class="flex flex-col">
            <span v-for="branch in customer.branches" :key="branch.id">
              {{ availableBranches.find(x => x.id === branch.branchCompanyId)?.name }}
            </span>
          </div>
          <div v-else class="flex flex-col justify-around">
            <span>None</span>
          </div>
        </div>
        <div class="absolute bottom-0 right-0 p-5">
          <div
            v-if="edit_mode_section === 'company_details'"
            class="px-2 py-1 text-2xs rounded text-gray-600 border bordergray-600 cursor-pointer hover:text-white hover:bg-gray-600"
            @click="edit_mode_section = 'none'"
          >
            <i class="fa fa-times"></i>
          </div>
          <div
            v-if="edit_mode_section !== 'company_details'"
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'company_details'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>

      <div
        v-if="showRequotePricingScreen"
        class="flex flex-wrap bg-white border m-3 md:m-10 relative pr-16"
      >
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Currency</div>
          <manage-currency
            v-model="customer.financial.currencyId"
            :edit_mode="false"
          ></manage-currency>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Default Tax Rate</div>
          <div class="flex flex-col justify-around">
            {{ selected_tax_rate_label }}
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Payment with order</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.financial.paymentWithOrder ? 'Yes' : 'No'"
          ></div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">See Price Breakdown</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.financial.omitPriceBreakdown ? 'No' : 'Yes'"
          ></div>
        </div>
        <div v-if="customer.delivery.minimumOrderValue" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Minimum Order Value</div>
          <div class="flex flex-col justify-around">
            {{ customer.delivery.minimumOrderValue }}
          </div>
        </div>
        <div v-if="customer.delivery.deliveryCharge" class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Delivery Charge</div>
          <div class="flex flex-col justify-around">
            {{ customer.delivery.deliveryCharge }}
          </div>
        </div>
        <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4">
          <div class="font-bold mb-2">Use Customer Charge</div>
          <div
            class="flex flex-col justify-around"
            v-text="customer.delivery.useCustomerCharge ? 'Yes' : 'No'"
          ></div>
        </div>
        <div class="absolute bottom-0 right-0 p-5">
          <div
            class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
            @click="edit_mode_section = 'financial_details'"
          >
            <i class="fa fa-pencil"></i>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    'account-code': () => import('@/components/business/customer/AccountCode.vue'),
    'company-details': () => import('@/components/business/customer/CompanyDetails.vue'),
    'financial-details': () => import('@/components/business/customer/FinancialDetails.vue'),
    'customer-navigation-menu': () => import('@/components/business/customer/Menu.vue'),
    'manage-currency': () => import('@/components/shared/ManageCurrency.vue'),
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      showRequotePricingScreen: undefined,
      loggedInUser: {
        id: 0,
        isAdmin: false,
      },
      staffContacts: [],
      tax_rates: [],
      loading: true,
      logo_src: false,
      edit_mode_section: 'none',
      company_logo_missing: false,
      availableBranches: []
    };
  },
  computed: {
    ...mapState('auth', {
      isBranchManagerAvailable: 'isBranchManagerAvailable',
    }),
    ...mapGetters({
      isQuoteEmailDetailEditingEnabled: 'feature/getIsQuoteEmailDetailEditingEnabled',
    }),
    hasDebugToggle() {
      return typeof this.customer?.customer?.debug !== 'undefined';
    },
    selected_tax_rate_label() {
      const taxRate = this.tax_rates.find(
        (rate) => rate.id === this.customer.financial.defaultTaxRateId,
      );
      if (taxRate === undefined) {
        return '';
      }
      return `${taxRate.name} (${taxRate.value}%)`;
    },
    deliverySalesSectorType() {
      return Object.keys(this.enums.salesSectorType).map((key) => [
        key,
        this.enums.salesSectorType[key],
      ]);
    },
  },
  async mounted() {
    await this.loadCustomer();
    this.staffContacts = await this.customerListContacts();
    this.tax_rates = await this.staffGetTaxRates();
    this.showRequotePricingScreen = await this.isRequotePriceChangeEnabled();
    const { branches } = await window.touch.customerListBranches(1000, 0);
    this.availableBranches = branches
  },
  methods: {
    ...mapActions({
      getCompany: 'user/customerGetCompany',
      isRequotePriceChangeEnabled: 'feature/isRequotePriceChangeEnabled',
      customerGet: 'user/customerGet',
      customerListContacts: 'user/customerListContacts',
      staffGetTaxRates: 'user/staffGetTaxRates',
    }),
    ...mapGetters({
      user: 'auth/user',
    }),
    async loadCustomer() {
      this.loading = true;
      this.customer.newLogo = null;
      this.logo_src = window.touch.imageBaseURL + this.customer.styling.logo;
      if (this.$store.state.auth.loggedIn) {
        this.loggedInUser.id = this.$store.state.auth.id;
        this.loggedInUser.isAdmin = this.$store.state.auth.admin;
      }
      this.loading = false;
    },
  },
};
</script>
