<template>
  <div>
    <b>{{ label }} </b>
    <span class="cursor-pointer inline-block relative">
      <span
        class="inline-block bg-gray-400 rounded p-1 text-white" 
        @click="dropdown = !dropdown"
      >
        <i class="fal fa-filter mx-1"></i>
        <b v-if="$route.query[queryLabel] && filters.some(x => x.id == $route.query[queryLabel])">
          {{ filters.find(x => x.id == $route.query[queryLabel]).name }}
        </b>
        <i v-if="dropdown" class="fal fa-chevron-up mx-1"></i>
        <i v-else class="fal fa-chevron-down mx-1"></i>
      </span>
      <div v-show="dropdown" class="relative z-50">
        <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded mt-1 right-0" style="width: max-content">
          <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
            <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Filter Status</div>
            <div class="flex flex-col justify-around">
              <i class="fal fa-times" @click.stop="dropdown = false"></i>
            </div>
          </div>
          <div class="mt-2">
            <div
              v-for="filter in filters"
              :key="filter.id"
              class="py-2 px-4 hover:bg-gray-200 flex"
              @click="toggleFilter(filter.id)"
            >
              <span>
                <span class="inline-block rounded-full w-2 h-2 mr-2" :class="[filter.dot ? filter.dot : 'bg-gray-500']"></span>
                <strong>{{ filter.name }}</strong>
              </span>
              <span v-show="$route.query[queryLabel] && $route.query[queryLabel] == filter.id" class="text-green-600 pl-2">
                <i class="fal fa-check"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </span>
    <div v-if="dropdown" class="fixed w-full h-full top-0 left-0 z-40" @click="dropdown = false"></div>
  </div>
</template>

<script>

export default {
  props: {
    filters: Array,
    label: String,
    queryLabel: String,
    defaultRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdown: false,
    };
  },
  methods: {
    toggleFilter(filter) {
      let query = {}
      query[this.queryLabel] = (this.$route.query[this.queryLabel] == filter) && !this.defaultRequired ? undefined : filter
      this.$router.push({ query: { ...this.$route.query, ...query} }).catch(() => {});
      this.dropdown = false
    },
    setDefaultFilter() {
      this.$router.replace({ query: undefined }).catch(() => {});
      this.dropdown = false
    },
  }
};
</script>